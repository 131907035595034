import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect:'/login',
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/home',     // 管理系统
        name: 'Home',
        component: () => import('../views/Home.vue'),
        redirect: '/home/table',
        children: [
            {path:'/home/table', name:'Table', component: () => import('../views/Table')},
            {path: '/purchase/record', name:'Record',component: () => import('../views/purchase/Record')},
            {path: '/purchase/list', name:'List',component: () => import('../views/purchase/List')},
            {path: '/stock/detail', name:'StockDetail',component: () => import('../views/stock/Detail')},
            {path: '/sale/detail', name:'SaleDetail',component: () => import('../views/sale/Detail')},
            {path: '/profit', name:'Profit',component: () => import('../views/profit/Profit')},

        ]
    },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
