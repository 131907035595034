import axios from "axios";
import router from "@/router";

//创建一个新的axios对象
const request = axios.create({
    baseURL : 'http://121.39.194.110:9171',    //后端的接口地址  ip:port
    timeout : 30000                       //3s , 接口的超时定义，如果3s内无返回，报错
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config =>{
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let token = JSON.parse(localStorage.getItem("user-token"))
    if (token) {
        config.headers['token'] = token;   //设置请求头
    }
    return config
}, error => {
    console.error(' request error: ' + error )   //for debug
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        // 兼容服务端返回的字符串数据
        if(typeof res == 'String'){
            res = res ? JSON.parse(res) : res
        }
        if (res.code === '401') {
            router.push("/login")
        }
        return res;
    },
    error => {
        console.error(' response error: ' + error )   //for debug
        return Promise.reject(error)
    }
)

export default request